var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            search: "",
            pagination: "",
            "max-items": "10",
            data: _vm.guideList
          },
          on: { search: _vm.handleSearch },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].description } },
                        [_vm._v(_vm._s(data[indextr].description))]
                      ),
                      _c("vs-td", { attrs: { data: data[indextr].id } }, [
                        _vm._v(
                          "https://neo-tool.com/?id=" + _vm._s(data[indextr].id)
                        )
                      ]),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].CreateDateGuide } },
                        [_vm._v(_vm._s(data[indextr].CreateDateGuide))]
                      )
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Guides.i18n) || _vm.resources.Guides.name
                )
              )
            ])
          ]),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Description.i18n) ||
                      _vm.resources.Description.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Link.i18n) || _vm.resources.Link.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.CreationDate.i18n) ||
                      _vm.resources.CreationDate.name
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full text-right" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "rgb(174,174,174)", type: "filled" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/tracer/summary")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Return.i18n) ||
                      _vm.resources.Return.name
                  )
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }