<template>
  <div>
    <vs-table @search="handleSearch" search pagination max-items="10" :data="guideList">
      <template slot="header">
        <h3>{{$t(resources.Guides.i18n) || resources.Guides.name}}</h3>
      </template>

      <template slot="thead">
        <vs-th>{{$t(resources.Description.i18n) || resources.Description.name}}</vs-th>
        <vs-th>{{$t(resources.Link.i18n) || resources.Link.name}}</vs-th>
        <vs-th>{{$t(resources.CreationDate.i18n) || resources.CreationDate.name}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
          <vs-td :data="data[indextr].id">https://neo-tool.com/?id={{ data[indextr].id }}</vs-td>
          <vs-td :data="data[indextr].CreateDateGuide">{{ data[indextr].CreateDateGuide }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row">
      <div class="vx-col w-full text-right">
        <vs-button
          color="rgb(174,174,174)"
          type="filled"
          @click="$router.push('/tracer/summary')"
        >{{$t(resources.Return.i18n) || resources.Return.name}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,

      resources: resources,
      alerts: alerts,
      guideList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      purchaseList: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      }
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.getTracer();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getTracer() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_NEOAPI}Guide/getByIta/${this.$i18n.locale}/${this.itaCode}`
      }).then(
        result => {
          if (result.data.length == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.guideList = [];
          } else {
            this.guideList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
    handleSearch(searching) {
      if (searching.length >= 3) {
        this.guideList = this.guideList.filter(x =>
          x.description.toLowerCase().includes(searching.toLowerCase())
        );
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>

